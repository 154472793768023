<section fxLayout="column" fxLayoutGap="2rem">
  <form autocomplete="off" [formGroup]="addDateForm">
    <section fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="start end">
      <mat-card-title>
        {{ title }}
      </mat-card-title>
      <div>
        <button
          mat-stroked-button
          [disabled]="disabled"
          (click)="datePicker.open()"
        >
          {{ labelAddButton }}
        </button>
      </div>
    </section>
    <mat-form-field fxHide>
      <mat-label>{{ labelAdd }}</mat-label>
      <input
        matInput
        formControlName="date"
        [matDatepicker]="datePicker"
        [matDatepickerFilter]="dateFilter"
        [min]="minDate"
        (dateChange)="addDate($event); datePicker.close()"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="datePicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #datePicker touchUi></mat-datepicker>
    </mat-form-field>
  </form>
  <div
    *ngIf="dates.length > 0; else noDates"
    fxLayout="row wrap"
    fxLayoutGap="5px grid"
  >
    <mat-chip-listbox>
      <mat-chip-option
        *ngFor="let d of dates"
        [selectable]="false"
        [removable]="true"
        (removed)="removeDate(d)"
      >
        {{ d | translocoDate: { dateStyle: 'long' } }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
  <ng-template #noDates>
    <p>{{ labelNoDates }}</p>
  </ng-template>
</section>
