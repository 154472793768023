import { isSameDay, startOfToday, isAfter } from 'date-fns';
import _ from 'lodash';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-list',
  templateUrl: './date-list.component.html',
  styleUrls: ['./date-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateListComponent {
  private _dates: Date[] = [];
  private _disabled = false;

  addDateForm = this.fb.group({
    date: [null],
  });

  @Input()
  title: string;

  @Input()
  labelAdd: string;

  @Input()
  labelAddButton: string;

  @Input()
  labelNoDates: string;

  @Input()
  minDate: Date = startOfToday();

  @Input()
  initialDates: Date[] = [];

  @Input()
  set dates(value: Date[]) {
    this._dates = value
      ? _.uniqWith<Date>(
          _.filter(value, (d) => isAfter(d, this.minDate)),
          isSameDay
        )
      : [];
  }

  get dates(): Date[] {
    return this._dates;
  }

  @Input()
  dateFilter: (d: Date | null) => boolean = (d) => true;

  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    if (disabled) {
      this.addDateForm.disable();
    } else {
      this.addDateForm.enable();
    }
  }

  @Output()
  dateRemoved = new EventEmitter<Date>();

  get disabled(): boolean {
    return this._disabled;
  }

  @Output()
  datesChanged = new EventEmitter<Date[]>();

  constructor(private fb: UntypedFormBuilder) {}

  cancelChanges() {
    this.addDateForm.reset();
    this.dates = this.initialDates;
  }

  addDate(event: MatDatepickerInputEvent<Date>) {
    this.selectedDate(event?.value);
  }

  removeDate(date: Date) {
    this.addDateForm.markAsDirty();
    this.dates = _.filter(this.dates, (d) => !isSameDay(d, date));
    this.datesChanged.emit(this.dates);
  }

  selectedDate(value: Date) {
    if (value) {
      this.addDateForm.markAsDirty();
      const date = value;
      if (!_.find(this.dates, (d) => isSameDay(d, date))) {
        this.dates = [
          ..._.sortBy(_.concat(this.dates, date), (d) => d.getTime()),
        ];
        this.datesChanged.emit(this.dates);
      }
      this.dateRemoved.emit(value);
    }
  }
}
